<template>
  <tbm-list :params="params"></tbm-list>
</template>
<script>
import tbmList from '@/views/business/components/tbmList';

export default {
  components: { tbmList },
  data () {
    return {
      params: {}
    }
  },
  methods: {
  },
  created () {
    let userId = JSON.parse(localStorage.getItem('userInfo')).id;
    let where = [];
    if (userId) {
      where.push({
        'field': 'pkUser',
        'opt': '=',
        'value': userId,
        'assemble': 'and'
      });
    }
    this.params = {
      url: '/hrtbm/applyOverTime/list',
      entity: {},
      where,
      orderBy: 'lastmodifiedTime desc',
      viewRouteName: 'tbm/overtimeApplication/overtimeApplicationApplyView',
      editRouteName: 'tbm/overtimeApplication/overtimeApplicationEdit',
      deleteUrl: '/hrtbm/applyOverTime/deleteOverTime',
      text: '加班申请'
    }
  }
}
</script>
