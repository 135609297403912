<template>
  <div class="tbmList">
    <van-nav-bar left-arrow :title="params.text" fixed @click-left="$router.push({ name: 'Home' })">
      <template #right>
        <van-icon name="plus" @click="goAdd" />
      </template>
    </van-nav-bar>
    <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
      <ul class="tbm-list">
        <li v-for="(item, index) in list" :key="index" @click="goView(item)">
          <div>
            <p>单据编号：{{ item.formNo }}</p>
            <div :style="{ color: item.formColor }">{{ item.formStatusName }}</div>
          </div>
          <div v-if="params.url == '/hrtbm/applyOverTime/list'">
            <p><label>申请日期 </label>{{ item.applyDate1 }}</p>
            <p><label>加班名称 </label>{{ item.name }}</p>
            <p><label>折合天数 </label>{{ item.totalDays }}</p>
          </div>
          <div v-else-if="params.url == '/hrtbm/applyUnusual/list'">
            <div v-for="(val, key) in item.applyUnusualList" :key="key" style="padding:0 10px">
              <div :style="{ borderBottom: key != item.applyUnusualList.length - 1 ? '1px solid #eee' : '' }">
                <p style="padding:10px 0">
                  <label>异常原因 </label>{{ unusualTypeList.find(items => items.value == val.unusualType).label }}
                </p>
                <p style="padding:10px 0">
                  <label>异常日期 </label>{{ val.dateDetail ? val.dateDetail.split(' ')[0] : val.dateDetail }}
                </p>
              </div>
            </div>
          </div>
          <div v-else>
            <p v-if="params.url == '/hrtbm/applyHoliday/list'"><label>休假类型 </label>{{ item.holidayName }}</p>
            <p><label>开始时间 </label>{{ item.beginDate1 }}</p>
            <p><label>结束时间 </label>{{ item.endDate1 }}</p>
            <p><label>折合天数 </label>{{ item.totalDays }}</p>
          </div>
          <div v-if="item.formStatus == 0 && params.deleteUrl" class="deleteList" @click.stop="deleteForm(item, index)">
            <span>删除</span>
          </div>
          <div v-if="item.formStatus == 3 && params.changeRouteName" class="changeList" @click.stop="formChange(item)">
            <span v-if="item.changeFormStatus == '1' || item.changeFormStatus == '2'">变更中</span>
            <span v-else-if="item.changeFormStatus == '3'">已变更</span>
            <span v-else>变更</span>
          </div>
        </li>
      </ul>
    </van-list>
  </div>
</template>
<script>
export default {
  props: {
    params: {
      type: [Object],
      default: function() {
        return;
      }
    }
  },
  data() {
    return {
      loading: false,
      finished: false,
      pageNum: 0,
      pageSize: 10,
      list: [],
      formStatus: [
        { label: '暂存', color: '#318B80' },
        { label: '审核中', color: '#FF7405' },
        { label: '作废', color: '#999999' },
        { label: '通过审核', color: '#4378BE' }
      ],
      unusualTypeList: [
        { value: '05', label: '设备故障' },
        { value: '06', label: '交通管制' },
        { value: '07', label: '交通故障' },
        { value: '00', label: '忘记打卡' }
      ]
    };
  },
  methods: {
    onLoad() {
      const { url, entity, orderBy, where } = this.params;
      this.$myHttp({
        method: 'post',
        url: url,
        data: {
          entity,
          where,
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          orderBy
        }
      }).then(res => {
        if (res.status == 200) {
          this.loading = false;
          this.pageNum++;
          res.data.rows.forEach(item => {
            if (item.beginDate) {
              item.beginDate1 = item.beginDate.split(' ')[0];
              item.endDate1 = item.endDate.split(' ')[0];
            }
            if (item.applyDate) {
              item.applyDate1 = item.applyDate.split(' ')[0];
            }
            item.formStatusName = this.formStatus[item.formStatus].label;
            item.formColor = this.formStatus[item.formStatus].color;
          }, this);
          this.list = this.list.concat(res.data.rows);
          if (res.data.total <= this.list.length) {
            this.finished = true;
          }
        } else {
          this.loading = false;
          this.finished = true;
        }
      });
    },
    goView(row) {
      let query = {};
      var id = row.id === null || row.id === '' ? row.pkUser : row.id;
      let namePk = '';
      if (this.params.url == '/hrtbm/applyHoliday/list') {
        namePk = 'pkHolidayChange';
      } else if (this.params.url == '/hrtbm/applyOfficeout/list') {
        namePk = 'pkParent';
      }
      if (this.params.changeRouteName && row[namePk] !== '' && row[namePk] !== null) {
        this.name = this.params.changeRouteName;
        query.pkParent = row[namePk];
      } else {
        if (row.formStatus == 0) {
          this.name = this.params.editRouteName;
        } else {
          this.name = this.params.viewRouteName;
        }
        query.businessKey = id;
      }

      this.$router.push({
        name,
        query
      });
    },
    goAdd() {
      if (this.params.editRouteName) {
        this.$router.push({
          name: this.params.editRouteName
        });
      }
    },
    formChange(row) {
      var id = row.id === null || row.id === '' ? row.pkUser : row.id;
      this.$router.push({
        name: this.params.changeRouteName,
        query: {
          pkParent: id
        }
      });
    },
    deleteForm(item, index) {
      this.$dialog
        .confirm({
          title: '删除',
          message: '确认删除吗'
        })
        .then(() => {
          let ruleForm = { ...item };
          delete ruleForm.beginDate1;
          delete ruleForm.endDate1;
          delete ruleForm.formStatusName;
          delete ruleForm.formColor;
          delete ruleForm.applyDate1;
          if (ruleForm.id !== '') {
            this.$myHttp({
              method: 'POST',
              url: this.params.deleteUrl,
              data: {
                entity: ruleForm
              }
            }).then(res => {
              if (res.status == 200) {
                this.$toast.success('删除成功');
                this.list.splice(index, 1);
              }
            });
          }
        })
        .catch(() => {
          // on cancel
        });
    }
  },
  created() {}
};
</script>
<style scoped>
.tbm-list {
  padding: 10px;
}

.tbm-list li {
  background: #fff;
  border-radius: 10px;
  margin-bottom: 10px;
  padding-bottom: 10px;
  position: relative;
}

.tbm-list li > div:nth-child(1) {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
  padding: 0 10px;
}

.tbm-list li > div:nth-child(2) p {
  display: flex;
  align-items: center;
  padding: 10px;
}

.tbm-list li > div:nth-child(2) p label {
  width: 80px;
  text-align: right;
  margin-right: 10px;
  color: #646566;
}

.tbm-list li > div:nth-child(3) {
  position: absolute;
  right: 10px;
  bottom: 15px;
  padding: 5px 15px;
  border-radius: 46px;
}

.tbm-list li .deleteList {
  border: 1px solid rgba(240, 45, 45, 1);
  color: #f02d2d;
}

.tbm-list li .changeList {
  background: rgba(67, 120, 190, 1);
  color: #fff;
}
</style>
<style>
.tbmList .van-list {
  padding: 46px 0 0px;
}
</style>
